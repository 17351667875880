// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/home/vsts/work/1/s/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/home/vsts/work/1/s/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laws-by-state-js": () => import("/home/vsts/work/1/s/src/pages/LawsByState.js" /* webpackChunkName: "component---src-pages-laws-by-state-js" */),
  "component---src-pages-new-leave-request-step-1-js": () => import("/home/vsts/work/1/s/src/pages/New_Leave_Request_step1.js" /* webpackChunkName: "component---src-pages-new-leave-request-step-1-js" */),
  "component---src-pages-new-leave-request-step-2-js": () => import("/home/vsts/work/1/s/src/pages/New_Leave_Request_step2.js" /* webpackChunkName: "component---src-pages-new-leave-request-step-2-js" */),
  "component---src-pages-new-leave-request-step-3-js": () => import("/home/vsts/work/1/s/src/pages/New_Leave_Request_step3.js" /* webpackChunkName: "component---src-pages-new-leave-request-step-3-js" */),
  "component---src-pages-new-leave-request-step-4-js": () => import("/home/vsts/work/1/s/src/pages/New_Leave_Request_step4.js" /* webpackChunkName: "component---src-pages-new-leave-request-step-4-js" */),
  "component---src-pages-new-leave-request-step-5-js": () => import("/home/vsts/work/1/s/src/pages/New_Leave_Request_step5.js" /* webpackChunkName: "component---src-pages-new-leave-request-step-5-js" */),
  "component---src-pages-new-leave-request-thankyou-js": () => import("/home/vsts/work/1/s/src/pages/New_Leave_Request_thankyou.js" /* webpackChunkName: "component---src-pages-new-leave-request-thankyou-js" */),
  "component---src-pages-overview-js": () => import("/home/vsts/work/1/s/src/pages/Overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-view-leave-request-js": () => import("/home/vsts/work/1/s/src/pages/ViewLeaveRequest.js" /* webpackChunkName: "component---src-pages-view-leave-request-js" */)
}

